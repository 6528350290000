import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, Grid, Toolbar, Typography } from '@material-ui/core'
import useStyles from './layout.styles'
import { ButtonLink } from '../ButtonLink'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

export const Layout = ({ children }) => {
  const classes = useStyles()
  const { logout, isAuthenticated } = useAuth0()
  return (
    <Grid container direction="column">
      <AppBar position="static" className={classes.container}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <Link className={classes.logo} to="/">
              Perch
            </Link>
          </Typography>
          {isAuthenticated && (
            <>
              <ButtonLink className={classes.button} color="inherit" to="/">
                Interactive Control
              </ButtonLink>
              <ButtonLink
                className={classes.button}
                color="inherit"
                to="/commands"
              >
                Commands
              </ButtonLink>
              <ButtonLink
                className={classes.button}
                color="inherit"
                to="/tickets"
              >
                Support Tickets
              </ButtonLink>
              <ButtonLink
                className={classes.buttonLogout}
                color="inherit"
                to="/"
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                Logout
              </ButtonLink>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Grid container justify="center">
        {children}
      </Grid>
    </Grid>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
