import { makeStyles } from '@material-ui/core'

export default makeStyles(() => ({
  container: {
    maxWidth: 1280,
  },
  card: {
    padding: 16,
    minWidth: 320,
    justifyContent: 'center',
    display: 'flex',
  },
  button: {
    marginTop: 16,
  },
}))
