import React, { useCallback } from 'react'
import { Button, Card, Grid } from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { Layout } from '../../core/Layout'
import useStyles from './interactive-control.styles'
import { useHistory, useParams } from 'react-router-dom'
import { validationSchema } from './validation'
import { TerminalController } from './Terminal'

export const InteractiveControl = () => {
  const classes = useStyles()
  const { push } = useHistory()
  const { device } = useParams()
  const onSubmit = useCallback(
    values => {
      push(`/terminal/${values.device}`)
    },
    [push]
  )
  return (
    <Layout>
      <Grid container justify="center" className={classes.container}>
        <Card elevation={2} className={classes.card}>
          <Formik
            onSubmit={onSubmit}
            initialValues={{ device }}
            validationSchema={validationSchema}
          >
            <Form>
              <Grid container alignContent="center" direction="column">
                <Field
                  component={TextField}
                  variant="outlined"
                  label="Device ID"
                  name="device"
                  type="text"
                />
                <Button
                  type="submit"
                  className={classes.button}
                  color="primary"
                  variant="contained"
                >
                  Connect
                </Button>
              </Grid>
            </Form>
          </Formik>
        </Card>
      </Grid>
      {device && (
        <Grid container justify="center" className={classes.container}>
          <Card elevation={2} className={classes.terminal}>
            <TerminalController deviceId={device} />
          </Card>
        </Grid>
      )}
    </Layout>
  )
}
