import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Terminal, { ColorMode, LineType } from 'react-terminal-ui'
import { API_TOKEN } from '../../utils/constants'
import { getEnv } from '../../services/env.service'

const apiUrl = getEnv('REACT_APP_PUBLIC_API_URL')

export const TerminalController = ({ deviceId }) => {
  const [terminalLineData, setTerminalLineData] = useState([
    { type: LineType.Output, value: `Welcome to device ${deviceId} terminal` },
  ])
  const onInput = useCallback(
    terminalInput => {
      const newData = [
        ...terminalLineData,
        { type: LineType.Input, value: terminalInput },
        { type: LineType.Output, value: 'Waiting response...' },
      ]
      setTerminalLineData(newData)
      fetch(`${apiUrl}/iot/device/`, {
        body: JSON.stringify({
          device: deviceId,
          command: terminalInput,
        }),
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem(API_TOKEN)}`,
        },
      })
        .then(res => res.json())
        .then(res => {
          if (!res) {
            setTerminalLineData([
              ...newData,
              {
                type: LineType.Output,
                value: 'No response received, i suggest you check the network',
              },
            ])
          } else {
            if (res.error) {
              setTerminalLineData([
                ...newData,
                {
                  type: LineType.Output,
                  value: `Error: ${res.error}`,
                },
              ])
            } else {
              setTerminalLineData([
                ...newData,
                {
                  type: LineType.Output,
                  value: res.data || 'No response from command execution',
                },
              ])
            }
          }
        })
        .catch(err => {
          setTerminalLineData([
            ...newData,
            { type: LineType.Output, value: `Error: ${err.message}` },
          ])
        })
    },
    [setTerminalLineData, terminalLineData, deviceId]
  )

  return (
    <Terminal
      name="Perch IOT Core terminal"
      colorMode={ColorMode.Dark}
      lineData={terminalLineData}
      onInput={onInput}
    />
  )
}

TerminalController.propTypes = {
  deviceId: PropTypes.string.isRequired,
}
