import axios from 'axios'
import { getEnv } from '../services/env.service'
const apiUrl = getEnv('REACT_APP_PUBLIC_API_URL')

const http = axios.create({
  baseURL: `${apiUrl}`,
  headers: {
    'Content-type': 'application/json',
  },
})
export default http
