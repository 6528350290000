import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, Card, Grid } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

import { Layout } from '../../core/Layout'
import useStyles from './login.styles'

export const Login = () => {
  const { search } = useLocation()
  const query = useMemo(() => {
    return new URLSearchParams(search)
  }, [search])
  const redirectTo = query.get('redirectTo')
  const { loginWithRedirect } = useAuth0()
  const login = useCallback(() => {
    loginWithRedirect({
      redirectUri: `${document.location.origin}?redirectTo=${
        redirectTo || encodeURI('/')
      }`,
    })
  }, [loginWithRedirect, redirectTo])
  const classes = useStyles()
  return (
    <Layout>
      <Grid container justify="center" className={classes.container}>
        <Card elevation={2} className={classes.card}>
          <Button
            onClick={login}
            className={classes.button}
            color="primary"
            variant="contained"
          >
            Login
          </Button>
        </Card>
      </Grid>
    </Layout>
  )
}

Login.propTypes = {
  redirectTo: PropTypes.string,
}
