// Actions
export const SET_ERROR = 'errorHandling/SET_ERROR'
export const HIDE_ERROR = 'errorHandling/HIDE_ERROR'

//initial state
const initialState = {
  error: null,
  isOpen: false,
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ERROR:
      // Perform action
      return {
        ...state,
        error: action.error,
        isOpen: true,
      }
    case HIDE_ERROR:
      // Perform action
      return {
        ...state,
        error: null,
        isOpen: false,
      }
    default:
      return state
  }
}

// Action Creators
export function setError(error) {
  return { type: SET_ERROR, error }
}

export function hideError() {
  return {
    type: HIDE_ERROR,
  }
}
