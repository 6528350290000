import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

import useStyles from './button-link.styles'

export const ButtonLink = ({ to, children, ...props }) => {
  const classes = useStyles()
  return (
    <Button {...props}>
      <Link to={to} className={classes.link}>
        {children}
      </Link>
    </Button>
  )
}

ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
