// Actions
export const SET_SUCCESS = 'successHandling/SET_SUCCESS'
export const HIDE_SUCCESS = 'successHandling/HIDE_SUCCESS'

//initial state
const initialState = {
  message: null,
  isOpen: false,
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SUCCESS:
      // Perform action
      return {
        ...state,
        message: action.message,
        isOpen: true,
      }
    case HIDE_SUCCESS:
      // Perform action
      return {
        ...state,
        message: null,
        isOpen: false,
      }
    default:
      return state
  }
}

// Action Creators
export function setSuccess(message) {
  return { type: SET_SUCCESS, message }
}

export function hideSuccess() {
  return {
    type: HIDE_SUCCESS,
  }
}
