import React, { useCallback, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  LinearProgress,
} from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'

import {
  createCommand,
  updateCommand,
  deleteCommand,
  keywordCommandSearch,
  paginateCommands,
} from '../../store/modules/command/command'

import { Layout } from '../../core/Layout'
import useStyles from './command.styles'
import { CommandForm } from './components/command-form'
import { CommandDelete } from './components/command-delete'
import { useDebouncedSearch } from '../../core/hooks/debounce-search'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const Command = () => {
  const [openModal, setOpenModal] = useState(false)
  const [commandToEdit, setCommandToEdit] = useState(null)
  const classes = useStyles()
  const list = useSelector(state => state.command.list)
  const loading = useSelector(state => state.command.loading)
  const page = useSelector(state => state.command.page)
  const pageSize = useSelector(state => state.command.pageSize)
  const total = useSelector(state => state.command.total)
  const dispatch = useDispatch()

  const searchCommands = useCallback(
    text => {
      dispatch(keywordCommandSearch(text))
    },
    [dispatch]
  )
  const { inputText, setInputText } = useDebouncedSearch(searchCommands)
  const onPaginate = useCallback(
    ({ page: newPage }) => {
      dispatch(paginateCommands({ page: newPage + 1, keyword: inputText }))
    },
    [dispatch, inputText]
  )

  const editCommand = useCallback(
    row => {
      setCommandToEdit(row)
      setOpenModal(true)
    },
    [setCommandToEdit]
  )

  const columns = [
    { field: 'errorCode', headerName: 'Error Code', width: 150 },
    { field: 'errorName', headerName: 'Error Name', width: 150 },
    { field: 'errorDescription', headerName: 'Error Description', width: 350 },
    { field: 'command', headerName: 'Command', width: 350 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      // eslint-disable-next-line react/display-name,no-console
      renderCell: params => (
        <>
          <Button
            className={classes.editCommand}
            variant="outlined"
            color="primary"
            onClick={() => editCommand(params.row)}
          >
            EDIT
          </Button>
          <CommandDelete onDelete={() => onDelete(params.row)} />
        </>
      ),
    },
  ]

  const handleClickOpen = useCallback(() => {
    setOpenModal(true)
    setCommandToEdit(null)
  }, [setOpenModal])

  const handleClose = useCallback(() => {
    setOpenModal(false)
    setCommandToEdit(null)
  }, [setOpenModal])

  const handleInputChange = useCallback(
    evt => {
      setInputText(evt.target.value)
    },
    [setInputText]
  )

  const saveCommand = useCallback(
    data => {
      if (commandToEdit) {
        dispatch(updateCommand(commandToEdit.id, data))
      } else {
        dispatch(createCommand(data))
      }
      setOpenModal(false)
      setCommandToEdit(null)
    },
    [commandToEdit, dispatch]
  )

  const onDelete = useCallback(
    data => {
      dispatch(deleteCommand(data))
    },
    [dispatch]
  )

  return (
    <Layout>
      <Grid container className={classes.container} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">
            Commands List
          </Typography>
        </Grid>
        <Grid className={classes.displayFlex} item xs={12}>
          <Button
            variant="outlined"
            className={classes.buttonMarginRight}
            color="primary"
            onClick={handleClickOpen}
          >
            Add new command
          </Button>
          <TextField
            id="outlined-basic"
            label="Enter search term"
            size="small"
            onChange={handleInputChange}
          />
          <Dialog
            TransitionComponent={Transition}
            className={classes.modal}
            open={openModal}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
          >
            <DialogTitle id="form-dialog-title">New Command</DialogTitle>
            <DialogContent>
              <CommandForm
                onSubmit={saveCommand}
                onCancel={handleClose}
                command={commandToEdit}
              />
            </DialogContent>
          </Dialog>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.table}>
            {!!list?.length && (
              <DataGrid
                rows={list}
                columns={columns}
                autoHeight
                rowCount={total}
                page={page ? page - 1 : page}
                paginationMode="server"
                pageSize={pageSize}
                onPageChange={onPaginate}
                disableColumnMenu
                autoPageSize={false}
                disableColumnSelector
                disableSelectionOnClick
              />
            )}
            {loading && <LinearProgress />}
          </div>
        </Grid>
      </Grid>
    </Layout>
  )
}
