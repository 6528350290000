import ApiServices from '../../../services/api.service'
import { setError } from '../errorHandling/errorHandling'
import { setSuccess } from '../successHandling/successHandling'
import qs from 'querystring'

//API version
const API_VERSION = '/iot'

// Actions
export const LOAD = 'command/LOAD'
export const LOAD_SUCCESS = 'command/LOAD_SUCCESS'
export const LOAD_FAIL = 'command/LOAD_FAIL'
export const CREATE = 'command/CREATE'
export const CREATE_SUCCESS = 'command/CREATE_SUCCESS'
export const CREATE_FAIL = 'command/CREATE_FAIL'
export const UPDATE = 'command/UPDATE'
export const UPDATE_SUCCESS = 'command/UPDATE_SUCCESS'
export const UPDATE_FAIL = 'command/UPDATE_FAIL'
export const DELETE = 'command/DELETE'
export const DELETE_SUCCESS = 'command/DELETE_SUCCESS'
export const DELETE_FAIL = 'command/DELETE_FAIL'
export const GET_COMMAND = 'command/GET_COMMAND'
export const GET_COMMAND_SUCCESS = 'command/GET_COMMAND_SUCCESS'
export const GET_COMMAND_FAIL = 'command/GET_COMMAND_FAIL'

//initial state
const initialState = {
  loading: false,
  list: null,
  page: 1,
  pageSize: 10,
  item: {},
  error: null,
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        loading: true,
        page: action.result ? action.result.page : 1,
        pageSize: action.result ? action.result.page_size : 10,
      }
    case CREATE:
    case UPDATE:
    case DELETE:
    case GET_COMMAND:
      return {
        ...state,
        loading: true,
      }
    case LOAD_FAIL:
      return {
        loading: false,
        error: action.error,
        page: 1,
        pageSize: 10,
        total: 0,
      }
    case CREATE_FAIL:
    case UPDATE_FAIL:
    case DELETE_FAIL:
    case GET_COMMAND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.result.data,
        page: action.result.page,
        pageSize: action.result.page_size,
        total: action.result.total,
        error: null,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, action.result],
        error: null,
      }
    case GET_COMMAND_SUCCESS:
    case UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.result,
        list: state.list.map(item =>
          item.id === action.result.id ? action.result : item
        ),
        error: null,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter(item => item.id !== action.result.id),
        error: null,
      }

    default:
      return state
  }
}

// Action Creators
export function load(data) {
  return { type: LOAD, result: data }
}
function loadSuccess(result) {
  return { type: LOAD_SUCCESS, result }
}
function loadFail(error) {
  return { type: LOAD_FAIL, error }
}
export function create() {
  return { type: CREATE }
}
function createSuccess(result) {
  return { type: CREATE_SUCCESS, result }
}
function createFail(error) {
  return { type: CREATE_FAIL, error }
}
export function update() {
  return { type: UPDATE }
}
function updateSuccess(result) {
  return { type: UPDATE_SUCCESS, result }
}
function updateFail(error) {
  return { type: UPDATE_FAIL, error }
}
function _delete() {
  return { type: DELETE }
}
function deleteSuccess(result) {
  return { type: DELETE_SUCCESS, result }
}
function deleteFail(error) {
  return { type: DELETE_FAIL, error }
}
function getCommand() {
  return { type: GET_COMMAND }
}
function getCommandSuccess(result) {
  return { type: GET_COMMAND_SUCCESS, result }
}
function getCommandFail(error) {
  return { type: GET_COMMAND_FAIL, error }
}

export function getCommands() {
  return async dispatch => {
    try {
      dispatch(load())
      const { data } = await ApiServices.get(`${API_VERSION}/command`)
      dispatch(loadSuccess(data))
    } catch (error) {
      dispatch(loadFail(error))
      dispatch(setError(error))
    }
  }
}

export function keywordCommandSearch(keyword) {
  return async (dispatch, getState) => {
    try {
      const state = getState()
      const { page } = state.ticket
      dispatch(load({ page }))
      const queryString = qs.stringify({ page, keyword })
      const { data } = await ApiServices.query(
        `${API_VERSION}/command/?${queryString}`
      )
      dispatch(loadSuccess(data))
    } catch (error) {
      dispatch(loadFail(error))
      dispatch(setError(error))
    }
  }
}

export function paginateCommands({ page, keyword }) {
  return async dispatch => {
    try {
      dispatch(load({ page }))
      const queryString = qs.stringify({ page, keyword })
      const { data } = await ApiServices.query(
        `${API_VERSION}/command/?${queryString}`
      )
      dispatch(loadSuccess(data))
    } catch (error) {
      dispatch(loadFail(error))
      dispatch(setError(error))
    }
  }
}

export function createCommand(body) {
  return async dispatch => {
    try {
      dispatch(create())
      const { data } = await ApiServices.post(`${API_VERSION}/command/`, body)
      dispatch(createSuccess(data))
      dispatch(setSuccess(`${data.id} was created`))
    } catch (error) {
      dispatch(createFail(error))
      dispatch(setError(error))
    }
  }
}

export function getCommandById(id) {
  return async dispatch => {
    try {
      dispatch(getCommand())
      const { data } = await ApiServices.get(`${API_VERSION}/command`, `${id}/`)
      dispatch(getCommandSuccess(data))
    } catch (error) {
      dispatch(getCommandFail(error))
      dispatch(setError(error))
    }
  }
}

export function updateCommand(id, body) {
  return async dispatch => {
    try {
      dispatch(update())
      const { data } = await ApiServices.patch(
        `${API_VERSION}/command/${id}/`,
        body
      )
      dispatch(updateSuccess(data))
      dispatch(setSuccess(`${data.id} was updated!`))
    } catch (error) {
      dispatch(updateFail(error))
      dispatch(setError(error))
    }
  }
}

export function deleteCommand(body) {
  return async dispatch => {
    try {
      dispatch(_delete())
      await ApiServices.delete(`${API_VERSION}/command/${body.id}/`)
      dispatch(deleteSuccess(body))
      dispatch(setSuccess(`${body.id} was deleted`))
    } catch (error) {
      dispatch(deleteFail(error))
      dispatch(setError(error))
    }
  }
}
