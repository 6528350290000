import { makeStyles } from '@material-ui/core'

export default makeStyles(() => ({
  container: {
    maxWidth: 1280,
  },
  card: {
    padding: 16,
    marginBottom: 10,
  },
  terminal: {
    width: '100%',
  },
  button: {
    marginTop: 16,
  },
}))
