const { createMuiTheme } = require('@material-ui/core')

export default createMuiTheme({
  palette: {
    primary: {
      main: '#17445c',
      dark: '#113649',
      light: '#21567a',
    },
  },
})
