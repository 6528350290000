import { makeStyles } from '@material-ui/core'

export default makeStyles(() => ({
  container: {
    marginBottom: 16,
  },
  button: {
    marginLeft: 8,
  },
  buttonLogout: {
    marginLeft: 'auto',
  },
  logo: {
    color: 'inherit',
    textDecoration: 'none',
    marginRight: 16,
  },
}))
