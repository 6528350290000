import React from 'react'
import theme from './material/theme'
import { ThemeProvider } from '@material-ui/core'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { Provider } from 'react-redux'

import { AuthGate } from './core/auth/AuthGate'
import ConfigureStore from './store/configureStore'

import { Login } from './pages/Login'
import { Command } from './pages/Command'
import { InteractiveControl } from './pages/InteractiveControl'
import { Unauthorized } from './pages/Unauthorized'

import './index.css'
import { Ticket } from './pages/Ticket'
import { getEnv } from './services/env.service'
const clientId = getEnv('REACT_APP_AUTH0_CLIENT_ID')
const domain = getEnv('REACT_APP_AUTH0_DOMAIN')

function App() {
  const store = ConfigureStore({})

  return (
    <div className="App">
      <Auth0Provider
        clientId={clientId}
        domain={domain}
        redirectUri={document.location.origin}
      >
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Router>
              <Switch>
                <Route path="/login">
                  <Login />
                </Route>
                <AuthGate>
                  <Route exact path="/terminal/:device">
                    <InteractiveControl />
                  </Route>
                  <Route path="/commands">
                    <Command />
                  </Route>
                  <Route path="/tickets">
                    <Ticket />
                  </Route>
                  <Route path="/unauthorized">
                    <Unauthorized />
                  </Route>
                  <Route path="/" exact>
                    <InteractiveControl />
                  </Route>
                </AuthGate>
              </Switch>
            </Router>
          </ThemeProvider>
        </Provider>
      </Auth0Provider>
    </div>
  )
}

export default App
