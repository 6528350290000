import ApiServices from '../../../services/api.service'
import { setError } from '../errorHandling/errorHandling'
import { setSuccess } from '../successHandling/successHandling'
import qs from 'querystring'
import { ticketDurations } from '../../../utils/date-time'

//API version
const API_VERSION = '/iot'

// Actions
export const LOAD = 'ticket/LOAD'
export const LOAD_SUCCESS = 'ticket/LOAD_SUCCESS'
export const LOAD_FAIL = 'ticket/LOAD_FAIL'
export const CREATE = 'ticket/CREATE'
export const CREATE_SUCCESS = 'ticket/CREATE_SUCCESS'
export const CREATE_FAIL = 'ticket/CREATE_FAIL'
export const UPDATE = 'ticket/UPDATE'
export const UPDATE_SUCCESS = 'ticket/UPDATE_SUCCESS'
export const UPDATE_FAIL = 'ticket/UPDATE_FAIL'
export const DELETE = 'ticket/DELETE'
export const DELETE_SUCCESS = 'ticket/DELETE_SUCCESS'
export const DELETE_FAIL = 'ticket/DELETE_FAIL'
export const GET_TICKET = 'ticket/GET_TICKET'
export const GET_TICKET_SUCCESS = 'ticket/GET_TICKET_SUCCESS'
export const GET_TICKET_FAIL = 'ticket/GET_TICKET_FAIL'

//initial state
const initialState = {
  loading: false,
  list: null,
  page: 1,
  pageSize: 10,
  item: {},
  error: null,
  total: 0,
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        loading: true,
        page: action.result ? action.result.page : 1,
        pageSize: action.result ? action.result.page_size : 10,
      }
    case CREATE:
    case UPDATE:
    case DELETE:
    case GET_TICKET:
      return {
        ...state,
        loading: true,
      }
    case LOAD_FAIL:
      return {
        loading: false,
        error: action.error,
        page: 1,
        pageSize: 10,
        total: 0,
      }
    case CREATE_FAIL:
    case UPDATE_FAIL:
    case DELETE_FAIL:
    case GET_TICKET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.result.data,
        page: action.result.page,
        pageSize: action.result.page_size,
        total: action.result.total,
        error: null,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [action.result, ...state.list],
        error: null,
      }
    case GET_TICKET_SUCCESS:
    case UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.result,
        list: state.list.map(item =>
          item.id === action.result.id ? action.result : item
        ),
        error: null,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter(item => item.id !== action.result.id),
        error: null,
      }

    default:
      return state
  }
}

// Action Creators
export function load(data) {
  return { type: LOAD, result: data }
}
function loadSuccess(result) {
  return { type: LOAD_SUCCESS, result }
}
function loadFail(error) {
  return { type: LOAD_FAIL, error }
}
export function create() {
  return { type: CREATE }
}
function createSuccess(result) {
  return { type: CREATE_SUCCESS, result }
}
function createFail(error) {
  return { type: CREATE_FAIL, error }
}
export function update() {
  return { type: UPDATE }
}
function updateSuccess(result) {
  return { type: UPDATE_SUCCESS, result }
}
function updateFail(error) {
  return { type: UPDATE_FAIL, error }
}
function _delete() {
  return { type: DELETE }
}
function deleteSuccess(result) {
  return { type: DELETE_SUCCESS, result }
}
function deleteFail(error) {
  return { type: DELETE_FAIL, error }
}
function getTicket() {
  return { type: GET_TICKET }
}
function getTicketSuccess(result) {
  return { type: GET_TICKET_SUCCESS, result }
}
function getTicketFail(error) {
  return { type: GET_TICKET_FAIL, error }
}

export function getTickets(query) {
  return async dispatch => {
    try {
      dispatch(load(query))
      const queryString = qs.stringify(query)
      const { data } = await ApiServices.query(
        `${API_VERSION}/ticket/?${queryString}`
      )
      const list = data.data.map(ticket => {
        return ticketDurations(ticket)
      })
      dispatch(loadSuccess({ ...data, data: list }))
    } catch (error) {
      dispatch(loadFail(error))
      dispatch(setError(error))
    }
  }
}

export function keywordTicketSearch(keyword) {
  return async (dispatch, getState) => {
    try {
      const state = getState()
      const { page } = state.ticket
      dispatch(load({ page }))
      const queryString = qs.stringify({ page, keyword })
      const { data } = await ApiServices.query(
        `${API_VERSION}/ticket/?${queryString}`
      )
      const list = data.data.map(ticket => {
        return ticketDurations(ticket)
      })
      dispatch(loadSuccess({ ...data, data: list }))
    } catch (error) {
      dispatch(loadFail(error))
      dispatch(setError(error))
    }
  }
}

export function paginateTickets({ page, keyword }) {
  return async dispatch => {
    try {
      dispatch(load({ page }))
      const queryString = qs.stringify({ page, keyword })
      const { data } = await ApiServices.query(
        `${API_VERSION}/ticket/?${queryString}`
      )
      const list = data.data.map(ticket => {
        return ticketDurations(ticket)
      })
      dispatch(loadSuccess({ ...data, data: list }))
    } catch (error) {
      dispatch(loadFail(error))
      dispatch(setError(error))
    }
  }
}

export function createTicket(body) {
  return async dispatch => {
    try {
      dispatch(create())
      const { data } = await ApiServices.post(`${API_VERSION}/ticket/`, body)
      dispatch(createSuccess(data))
      dispatch(setSuccess(`${data.id} was created`))
    } catch (error) {
      dispatch(createFail(error))
      dispatch(setError(error))
    }
  }
}

export function getTicketById(id) {
  return async dispatch => {
    try {
      dispatch(getTicket())
      const { data } = await ApiServices.get(`${API_VERSION}/ticket`, `${id}/`)
      dispatch(getTicketSuccess(data))
    } catch (error) {
      dispatch(getTicketFail(error))
      dispatch(setError(error))
    }
  }
}

export function updateTicket(id, body) {
  return async dispatch => {
    try {
      dispatch(update())
      const { data } = await ApiServices.patch(
        `${API_VERSION}/ticket/${id}/`,
        body
      )
      dispatch(updateSuccess(data))
      dispatch(setSuccess(`${data.id} was updated!`))
    } catch (error) {
      dispatch(updateFail(error))
      dispatch(setError(error))
    }
  }
}

export function deleteTicket(body) {
  return async dispatch => {
    try {
      dispatch(_delete())
      await ApiServices.delete(`${API_VERSION}/ticket/${body.id}/`)
      dispatch(deleteSuccess(body))
      dispatch(setSuccess(`${body.id} was deleted`))
    } catch (error) {
      dispatch(deleteFail(error))
      dispatch(setError(error))
    }
  }
}
