import { useEffect, useMemo, useState } from 'react'
import AwesomeDebouncePromise from 'awesome-debounce-promise'

export const useDebouncedSearch = searchFunction => {
  const [inputText, setInputText] = useState('')

  const debouncedSearchFunction = useMemo(
    () => AwesomeDebouncePromise(searchFunction, 500),
    [searchFunction]
  )

  useEffect(() => {
    debouncedSearchFunction(inputText)
  }, [inputText, debouncedSearchFunction])

  // Return everything needed for the hook consumer
  return {
    inputText,
    setInputText,
  }
}
