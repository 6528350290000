import React from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { validationSchema } from '../../validation'
import {
  Button,
  TextField,
  MenuItem,
  Select,
  FormHelperText,
  InputLabel,
} from '@material-ui/core'
import useStyles from '../../../Ticket/ticket.styles'

export const TicketForm = ({ ticket, commands, onSubmit, onCancel }) => {
  const classes = useStyles()

  const initialValues = {
    hostname: '',
    ticketNumber: '',
    errorCode: '',
  }

  const formik = useFormik({
    initialValues: ticket || initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="hostname"
          name="hostname"
          label="Hostname"
          value={formik.values.hostname}
          onChange={formik.handleChange}
          error={formik.touched.hostname && Boolean(formik.errors.hostname)}
          helperText={formik.touched.hostname && formik.errors.hostname}
        />
        <TextField
          fullWidth
          id="ticketNumber"
          name="ticketNumber"
          label="Ticket Number"
          type="ticketNumber"
          value={formik.values.ticketNumber}
          onChange={formik.handleChange}
          error={
            formik.touched.ticketNumber && Boolean(formik.errors.ticketNumber)
          }
          helperText={formik.touched.ticketNumber && formik.errors.ticketNumber}
        />
        <InputLabel shrink id="command">
          Error Code
        </InputLabel>
        <Select
          fullWidth
          labelId="errorCode"
          id="errorCode"
          name="errorCode"
          label="errorCode"
          value={formik.values.errorCode}
          onChange={formik.handleChange}
          error={formik.touched.errorCode && Boolean(formik.errors.errorCode)}
        >
          {commands.map(cmd => (
            <MenuItem value={cmd.errorCode} key={cmd.errorCode}>
              {cmd.errorCode}
            </MenuItem>
          ))}
        </Select>
        {formik.touched.errorCode && Boolean(formik.errors.errorCode) && (
          <FormHelperText>{formik.errors.errorCode}</FormHelperText>
        )}
        <div className={classes.actions}>
          <Button variant="contained" onClick={onCancel} color="default">
            Cancel
          </Button>
          <Button
            className={classes.buttonMarginLeft}
            variant="contained"
            type="submit"
            color="primary"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}

TicketForm.propTypes = {
  ticket: PropTypes.object,
  commands: PropTypes.array,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}
