import React from 'react'
import useStyles from './unauthorized.styles'
import { useAuth0 } from '@auth0/auth0-react'
import { Card, Grid, CardContent, Typography, Button } from '@material-ui/core'

export const Unauthorized = () => {
  const classes = useStyles()
  const { logout } = useAuth0()
  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      className={classes.container}
    >
      <Card elevation={2} className={classes.card}>
        <CardContent>
          <Typography variant="h5" component="h2">
            UNAUTHORIZED
          </Typography>
          <Typography variant="body1" gutterBottom>
            You are not allowed to visit this page.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Logout
          </Button>
        </CardContent>
      </Card>
    </Grid>
  )
}
