import React, { useState, useCallback, forwardRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  LinearProgress,
} from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'

import { Layout } from '../../core/Layout'

import {
  createTicket,
  // getTickets,
  updateTicket,
  deleteTicket,
  keywordTicketSearch,
  paginateTickets,
} from '../../store/modules/ticket/ticket'
import { getCommands } from '../../store/modules/command/command'

import useStyles from './ticket.styles'
import { TicketForm } from './components/ticket-form'
import { TICKET_STATUS } from '../../utils/constants'
import { TicketResponse } from './components/ticket-response'
import { TicketDelete } from './components/ticket-delete'
import { useDebouncedSearch } from '../../core/hooks/debounce-search'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const Ticket = () => {
  const [openModal, setOpenModal] = useState(false)
  const [ticketToEdit, setTicketToEdit] = useState(null)
  const classes = useStyles()
  const list = useSelector(state => state.ticket.list)
  const commands = useSelector(state => state.command.list)
  const loading = useSelector(state => state.ticket.loading)
  const page = useSelector(state => state.ticket.page)
  const pageSize = useSelector(state => state.ticket.pageSize)
  const total = useSelector(state => state.ticket.total)
  const dispatch = useDispatch()
  const searchTickets = useCallback(
    text => {
      dispatch(keywordTicketSearch(text))
    },
    [dispatch]
  )
  const { inputText, setInputText } = useDebouncedSearch(searchTickets)
  const onPaginate = useCallback(
    ({ page: newPage }) => {
      dispatch(paginateTickets({ page: newPage + 1, keyword: inputText }))
    },
    [dispatch, inputText]
  )

  const columns = [
    { field: 'hostname', headerName: 'Hostname', width: 180 },
    { field: 'createdAt', headerName: 'Created Date', width: 120 },
    { field: 'durationTime', headerName: 'Duration', width: 120 },
    { field: 'ticketNumber', headerName: 'Ticket Number', width: 120 },
    { field: 'command', headerName: 'Command', width: 200 },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      valueFormatter: params => TICKET_STATUS[params.value],
    },
    { field: 'errorCode', headerName: 'Error Code', width: 100 },
    { field: 'retryFlag', headerName: 'Retry Flag', width: 100 },
    {
      field: 'response',
      headerName: 'Response',
      width: 200,
      // eslint-disable-next-line react/display-name
      renderCell: params => <TicketResponse response={params.value} />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      // eslint-disable-next-line react/display-name,no-console
      renderCell: params => (
        <>
          <TicketDelete onDelete={() => onDelete(params.row)} />
        </>
      ),
    },
  ]

  const onDelete = useCallback(
    data => {
      dispatch(deleteTicket(data))
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(getCommands())
  }, [dispatch])

  const handleClickOpen = useCallback(() => {
    setOpenModal(true)
    setTicketToEdit(null)
  }, [setOpenModal])

  const handleClose = useCallback(() => {
    setOpenModal(false)
    setTicketToEdit(null)
  }, [setOpenModal])

  const handleInputChange = useCallback(
    evt => {
      setInputText(evt.target.value)
    },
    [setInputText]
  )

  const saveCommand = useCallback(
    data => {
      // eslint-disable-next-line no-console
      console.log('SAVE COMMAND')
      if (ticketToEdit) {
        dispatch(updateTicket(ticketToEdit.id, data))
      } else {
        dispatch(createTicket(data))
      }
      setOpenModal(false)
      setTicketToEdit(null)
    },
    [dispatch, ticketToEdit]
  )

  // const onDelete = useCallback(
  //   data => {
  //     dispatch(deleteCommand(data))
  //   },
  //   [ticketToEdit]
  // )

  return (
    <Layout>
      <Grid container className={classes.container} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">
            Ticket List
          </Typography>
        </Grid>
        <Grid className={classes.displayFlex} item xs={12}>
          <Button
            variant="outlined"
            className={classes.buttonMarginRight}
            color="primary"
            onClick={handleClickOpen}
          >
            Add new ticket
          </Button>
          <TextField
            id="outlined-basic"
            label="Enter search term"
            size="small"
            value={inputText}
            onChange={handleInputChange}
          />
          <Dialog
            TransitionComponent={Transition}
            className={classes.modal}
            open={openModal}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
          >
            <DialogTitle id="form-dialog-title">New Ticket</DialogTitle>
            <DialogContent>
              <TicketForm
                onSubmit={saveCommand}
                onCancel={handleClose}
                ticket={ticketToEdit}
                commands={commands}
              />
            </DialogContent>
          </Dialog>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.table}>
            {!!list?.length && (
              <DataGrid
                rows={list}
                columns={columns}
                autoHeight={true}
                disableColumnMenu={true}
                pageSize={pageSize}
                rowCount={total}
                paginationMode="server"
                page={page ? page - 1 : page}
                onPageChange={onPaginate}
                disableColumnSelector={true}
                disableSelectionOnClick={true}
              />
            )}
            {loading && <LinearProgress />}
          </div>
        </Grid>
      </Grid>
    </Layout>
  )
}
