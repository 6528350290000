import { makeStyles } from '@material-ui/core'

export default makeStyles(() => ({
  form: {
    width: 420,
  },
  container: {
    maxWidth: 1280,
  },
  table: {
    width: '100%',
    marginTop: 20,
  },
  modal: {
    width: '100%',
  },
  actions: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginTop: 20,
    '.button': {
      marginLeft: 10,
    },
  },
  buttonMarginLeft: {
    marginLeft: 10,
  },
  buttonMarginRight: {
    marginRight: 10,
  },
  editCommand: {
    marginRight: '1rem',
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}))
