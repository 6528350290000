import { validationSchema } from '../../validation'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { Button } from '@material-ui/core'
import React from 'react'
import useStyles from '../../command.styles'
import PropTypes from 'prop-types'

export const CommandForm = ({ command, onSubmit, onCancel }) => {
  const classes = useStyles()
  const initialValues = {
    errorCode: '',
    errorName: '',
    errorDescription: '',
    command: '',
  }

  return (
    <Formik
      className={classes.form}
      autoComplete="off"
      initialValues={command || initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Field
          autoFocus
          margin="dense"
          id="code"
          label="Error Code"
          fullWidth
          variant="outlined"
          component={TextField}
          name="errorCode"
        />
        <Field
          margin="dense"
          id="name"
          label="Error Name"
          fullWidth
          variant="outlined"
          component={TextField}
          name="errorName"
        />
        <Field
          margin="dense"
          id="description"
          label="Error Description"
          multiline
          rows={3}
          fullWidth
          variant="outlined"
          component={TextField}
          name="errorDescription"
        />
        <Field
          margin="dense"
          id="command"
          label="Command"
          fullWidth
          variant="outlined"
          component={TextField}
          name="command"
        />
        <div className={classes.actions}>
          <Button variant="contained" onClick={onCancel} color="default">
            Cancel
          </Button>
          <Button
            className={classes.buttonMarginLeft}
            variant="contained"
            type="submit"
            color="primary"
          >
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  )
}

CommandForm.propTypes = {
  command: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}
