import { makeStyles } from '@material-ui/core'

export default makeStyles(() => ({
  container: {
    maxWidth: 1280,
    margin: '0 auto',
  },
  card: {
    padding: 16,
    width: 320,
  },
}))
