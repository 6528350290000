import moment from 'moment'
import { TICKET_DATE_FORMAT } from './constants'

export const ticketDurations = ticket => {
  const createTime = moment(ticket.createdAt)
  const difference = moment(ticket.updatedAt).diff(createTime)
  ticket.durationTime = moment.utc(difference).format('HH:mm:ss.SSS')
  ticket.createdAt = createTime.format(TICKET_DATE_FORMAT)
  return ticket
}
