import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useAuth0 } from '@auth0/auth0-react'
import apiService from '../../services/api.service'
import { API_TOKEN, AUTH0_ROLE_KEY } from '../../utils/constants'
import { Loading } from '../Loading'

export const AuthGate = ({ children }) => {
  const [token, setToken] = useState('')
  const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0()
  const { push } = useHistory()
  const { path } = useRouteMatch()
  useEffect(() => {
    if (path !== 'login' && !isAuthenticated && !isLoading) {
      push(`/login?redirectTo=${encodeURI(path)}`)
    }
  }, [isAuthenticated, isLoading, push, path])
  useEffect(() => {
    if (isAuthenticated) {
      // eslint-disable-next-line no-extra-semi
      ;(async function getToken() {
        try {
          const token = await getIdTokenClaims()
          if (
            AUTH0_ROLE_KEY in token &&
            token[AUTH0_ROLE_KEY][0].includes('External')
          ) {
            push('/unauthorized')
          }
          localStorage.setItem(API_TOKEN, token.__raw)
          apiService.setToken(token.__raw)
          setToken(token.__raw)
        } catch (error) {
          localStorage.removeItem(API_TOKEN)
          setToken('')
        }
      })()
    } else {
      localStorage.removeItem(API_TOKEN)
      setToken('')
    }
  }, [getIdTokenClaims, setToken, isAuthenticated, push])

  if (
    (!token && isAuthenticated) ||
    isLoading ||
    (path !== '/login' && !isAuthenticated)
  ) {
    return (
      <Grid
        container
        justify="center"
        alignItems={'center'}
        style={{ height: '100vh' }}
      >
        <Loading />
      </Grid>
    )
  }
  return <>{children}</>
}

AuthGate.propTypes = {
  children: PropTypes.node.isRequired,
}
